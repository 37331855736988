'use client';

import mixpanel from 'mixpanel-browser';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ClerkProvider, SignedIn, SignedOut } from '@clerk/nextjs';
import { NotificationProvider } from '../context/NotificationContext';
import React, { Suspense } from 'react';
import NavWrapper from '../components/layout/NavWrapper';
import { usePathname } from 'next/navigation';

mixpanel.init(process.env.NEXT_PUBLIC_MIXPANEL_TOKEN ? process.env.NEXT_PUBLIC_MIXPANEL_TOKEN : 'some token');

export default function Providers({ children }: { children: React.ReactNode }) {
  const pathname = usePathname();
  const [queryClient] = React.useState(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            refetchOnWindowFocus: false,
          },
        },
      }),
  );

  const isAdminPage = pathname.startsWith('/admin');

  return (
    <>
      <Suspense fallback={<></>}>
        <NotificationProvider>
          <QueryClientProvider client={queryClient}>
            <ClerkProvider>
              <SignedIn>{isAdminPage ? children : <NavWrapper>{children}</NavWrapper>}</SignedIn>
              <SignedOut>{children}</SignedOut>
            </ClerkProvider>
          </QueryClientProvider>
        </NotificationProvider>
      </Suspense>
    </>
  );
}
